import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import router from '@/router'
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
export default function useSessionList() {
  // Use toast

  const toast = useToast();

  // if (Designations) emit('addDesignation')
  const refFamiliesListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "subject", label: "العنوان", sortable: true },

    { key: "type", label: "النوع", sortable: true },

    { key: "date", label: "تاريخ", sortable: true },

    { key: "actions" ,label: "الخيارات"},
  ];

  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refFamiliesListTable.value
      ? refFamiliesListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refFamiliesListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

 


  const fetchSession = (ctx, callback) => {
  
    return new Promise((resolve, reject) => {
      let url=`/api/v1/mobile-team/sessions`
      axios
      .get(url, {
        params: {
            search: searchQuery.value, // Assuming searchQuery.value holds the search query
            page_size: perPage.value, // Assuming perPage.value holds the number of items per page
            page: currentPage.value // Assuming currentPage.value holds the current page number
        }
    })
        .then(response => {
          const { data, total } = response.data;
        //  console.log(response.data)
          callback(data);
          // console.log( response.total)
          totalUsers.value = response.data.total;
        })
        .catch(error => {
          Vue.swal({
            title: "حدثت مشكلة في استرجاع البيانات",
    icon: "error",
         
      confirmButtonText: "موافق",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
    
         
        })
    })
   
      
  
       
      
  };

  return {

    fetchSession,

    tableColumns,
    perPage,
    currentPage,
   
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFamiliesListTable,

    refetchData,
  };
}
