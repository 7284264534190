import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
  
    fetchSession(ctx) {
      return new Promise((resolve, reject) => {
        let url=`/api/v1/mobile-team/sessions`
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    fetchsessionBenef(ctx,id) {
      return new Promise((resolve, reject) => {
        let url=`/api/v1/mobile-team/sessions/${id}`
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
  

    
  },
}
